.prices {
    margin-bottom: 40px;
}

.title {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 1.25em;
}

.price {
    background-color: #EEE;
    text-decoration: none;
    justify-content: space-between;
    padding: 12px 20px;
    border-radius: 50px;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    font-size: 0.85em;
}

.price:hover {
    background-color: #DDD;
}

.logo { 
    flex-basis: 5%;
    align-items: center;
    display: flex;
}

.logo img { 
    width: 30px;
}

.name { 
    color: #000;
    flex-basis: 50%;
 }

.value { 
	color: #000;
    flex-basis: 5%;
}

.cta { 
    flex-basis: 20%;
    font-weight: bold;
    padding: 5px 22px;
    background-color: #d8613c;
    border-radius: 35px;
    flex-basis: 15%;
    color: #FFF;
}

.price:hover .cta {
    background-color: #333;
}

@media screen and (max-width:640px) {
    .price {
        font-size: 1em;
    }

    .logo img { 
        width: 20px;
    }

    .cta > span { 
        display: none;
    }

    .cta {
        flex-basis: 10%;
    }

    .name { 
        flex-basis: 40%;
     }
}